<template>
    <Container small>
        <Garage
            :open="garage.show"
            :vehicle-id="vehicle.data?.Id || null"
            @close="garage.show = false"
        />

        <ZoomVehicleAccessories
            v-if="page.show"
            :open="zoom.show"
            :vehicle="vehicle"
            :wheel="wheel"
            :accessory="accessory.selected"
            :accessory-show-on-vehicle="accessory.showOnVehicle"
            @close="zoom.show = false"
        />

        <section
            v-if="page.show"
            :class="[
                'col-12 m-0 p-0',
                'text-brand-text bg-brand-body',
                'visualizer-container',
                filters.show && 'shadow-sm',
                (!$store.getters.device.isMobile || ($store.getters.device.isMobile && !$store.getters.device.isLandscape)) && 'sticky-top'
            ]"
        >
            <div class="d-flex flex-column justify-content-start align-items-start">
                <div class="w-100 order-0 previewer bg-brand-body position-relative">
                    <AccessoriesPreviewer
                        :class="[
                            page.error && 'previewer-disabled',
                            page.loading && 'loading'
                        ]"
                        :vehicle="vehicle"
                        :wheel="wheel"
                        :accessory="accessory.selected"
                        :accessory-show-on-vehicle="accessory.showOnVehicle"
                        @showOriginalVehicleImage="showOriginalVehicleImage"
                        @showSpecifications="showSpecifications"
                    >
                        <div class="go-back-button" @click.stop="vehicle.data && $router.push(builderURL)">
                            <GoBackButton disable-history-action/>
                        </div>

                        <div class="options-button d-flex justify-content-end align-items-center">
                            <div class="me-2 me-sm-3">
                                <IconButton zoom-in @activated="zoom.show = true"/>
                            </div>

                            <div class="me-2 me-sm-3">
                                <IconButton garage @activated="garage.show = true"/>
                            </div>
                        </div>
                    </AccessoriesPreviewer>
                </div>

                <div
                    v-show="vehicle.data && !accessory.showSpecifications"
                    class="w-100 order-2"
                >
                    <FiltersAccessories class="w-100 filters-container"/>
                </div>
            </div>
        </section>

        <section v-if="!accessory.showSpecifications" class="col-12 m-0 p-0 bg-brand-body">
            <div class="container-fluid m-0 p-0">
                <div v-show="!page.loading" class="row justify-content-start align-items-stretch p-0 px-lg-4 m-0">
                    <div
                        v-for="(item, index) in accessory.store"
                        :key="index"
                        class="col-6 col-md-4 col-lg-3 p-2 p-xxl-3 position-relative"
                    >
                        <div
                            :class="[
                                'accessory-card h-100',
                                'd-flex flex-column justify-content-around',
                                'px-1 py-2 p-sm-3 p-md-2 p-xxl-3',
                                'border-1 rounded',
                                'bg-brand-light-gray shadow-sm',
                                'toggle-active',
                                accessory.selected === item && 'bg-brand-light-gray',
                                accessory.selected === item ? 'border border-2' : 'border border-color-brand-light-gray border-2'
                            ]"
                            @click="accessorySelect(index)"
                        >
                            <div class="w-100 text-center px-2 px-sm-0 my-auto">
                                <img
                                    :src="item.images.thumbnail"
                                    class="accessory-image user-select-none"
                                    alt=""
                                >
                            </div>

                            <div class="w-100 pt-2 pt-sm-3 px-2 text-center accessory-model">
                                <span class="fw-medium">{{item.model || ''}}</span>
                            </div>
                            
                            <div class="text-center" style="font-size: 90%">
                                <small class="fw-medium py-1">{{item.type}}</small>
                            </div>

                            <div class="text-center">
                                <small>{{item.partNumber}}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section v-else class="col-12 m-0 p-0 bg-brand-body my-4">
            <div class="container-fluid m-0 p-0">
                <div class="row justify-content-start align-items-stretch p-0 px-lg-4 m-0">
                    <div
                        class="col-12 px-4 p-3 border-bottom"
                    >
                        <span class="fw-bold">SPECIFICATIONS</span>
                    </div>
                    <div
                        class="col-12 p-2 p-xxl-3 d-flex flex-wrap justify-content-start align-items-start"
                    >
                        <div class="m-3 text-nowrap">
                            <span class="fw-medium">Type: </span>
                            <span class="fw-regular">{{accessory.selected.type}}</span>
                        </div>
                        <div class="m-3 text-nowrap">
                            <span class="fw-medium">Brand: </span>
                            <span class="fw-regular">{{accessory.selected.brand}}</span>
                        </div>
                        <div class="m-3 text-nowrap">
                            <span class="fw-medium">Model: </span>
                            <span class="fw-regular">{{accessory.selected.model}}</span>
                        </div>
                        <div class="m-3 text-nowrap">
                            <span class="fw-medium">Style: </span>
                            <span class="fw-regular">{{accessory.selected.style}}</span>
                        </div>
                        <div class="m-3 text-nowrap">
                            <span class="fw-medium">Part number: </span>
                            <span class="fw-regular">{{accessory.selected.partNumber}}</span>
                        </div>
                        <div v-if="accessory.selected.size" class="m-3">
                            <span class="fw-medium">Size: </span>
                            <span class="fw-regular">{{accessory.selected.size}}</span>
                        </div>
                        <div class="m-3">
                            <span class="fw-medium">Description: </span>
                            <span class="fw-regular">{{accessory.selected.description}}</span>
                        </div>
                        <div class="m-3 text-nowrap">
                            <span class="fw-medium">Price: </span>
                            <span class="fw-bold text-brand-primary">$ {{accessory.selected.price}}</span>
                        </div>
                        <div class="m-3 text-nowrap">
                            <span class="fw-medium">Installed: </span>
                            <span class="fw-bold text-brand-primary">$ {{accessory.selected.installed}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Container>
</template>

<script>
import apiRequest from "@/utils/apiRequest";

import Container from "@/components/Container.vue";
import AccessoriesPreviewer from "@/components/builder/AccessoriesPreviewer.vue";
import FiltersAccessories from "@/components/builder/FiltersAccessories.vue";
import GoBackButton from "@/components/shared/GoBackButton.vue";
import IconButton from '@/components/shared/IconButton.vue';

import Garage from "@/components/builder/Garage.vue";
import ZoomVehicleAccessories from "@/components/builder/ZoomVehicleAccessories.vue";

export default {
    name: 'BuilderAccessories',
    components: {
        AccessoriesPreviewer,
        Container,
        FiltersAccessories,
        GoBackButton,
        Garage,
        IconButton,
        ZoomVehicleAccessories
    },
    data: function () {
        return {
            
            accessory: {
                selected: null,
                showSpecifications: false,
                showOnVehicle: true,
                store: [
                    {
                        type: 'Fender',
                        brand: 'Ford Accessories',
                        logoURL: 'https://storage.googleapis.com/autosync-vvs/static/temp/FordPartsCom_Blue_RGB_Hz_v1.svg',
                        model: 'FF Pocket Style 4Pc',
                        partNumber: 'BWR20963-02',
                        style: 'Pocket/Rivet',
                        description: 'These big, bold and brash flares have the bolted-on look that says “in your face,” but in reality, require no drilling for installation. Each flare adds 1-1/2" of tire coverage  Available pre-assembled - ready for installation; or unpainted – ready for prep & paint',
                        price: 499.00,
                        installed: 634.00,
                        images: {
                            thumbnail: 'https://storage.googleapis.com/autosync-vvs/static/temp/fender_thumbnail.png',
                            side: 'https://storage.googleapis.com/autosync-vvs/static/temp/001-fender.png',
                            front: 'https://storage.googleapis.com/autosync-vvs/static/temp/032-fender.png',
                            back: 'https://storage.cloud.google.com/autosync-vvs/static/temp/014-fender.png'
                        }
                    },
                    {
                        type: 'Step Bar',
                        brand: 'Ford Accessories',
                        logoURL: 'https://storage.googleapis.com/autosync-vvs/static/temp/FordPartsCom_Blue_RGB_Hz_v1.svg',
                        model: '5" Step Bars',
                        partNumber: 'FL3Z16450CB',
                        size: '5 Inch',
                        style: 'Charcoal Grey, SuperCrew',
                        description: 'These big, bold and brash flares have the bolted-on look that says “in your face,” but in reality, require no drilling for installation. Each flare adds 1-1/2" of tire coverage  Available pre-assembled - ready for installation; or unpainted – ready for prep & paint',
                        price: 600.00,
                        installed: 735.00,
                        images: {
                            thumbnail: 'https://storage.googleapis.com/autosync-vvs/static/temp/stepbar_thumbnail.png',
                            side: 'https://storage.googleapis.com/autosync-vvs/static/temp/001-stepbar.png',
                            front: 'https://storage.googleapis.com/autosync-vvs/static/temp/032-stepbar.png',
                            back: 'https://storage.googleapis.com/autosync-vvs/static/temp/014-stepbar.png'
                        }
                    }
                ]
            },
            filters: {
                show: false
            },
            garage:{
                show: false
            },
            page: {
                error: false,
                loading: true,
                show: false
            },
            vehicle: {
                anglesImgCodes: null,
                coordinates: null,
                colorBaseUrl: null,
                colorDefault: null,
                data: null,
                imgBaseUrl: null,
                inImageWheelDiameters: null
            },
            wheel: {
                data: null,
                imgBaseUrl: null,
                diameterSelected: null
            },
            zoom : {
                show: false
            }
        };
    },
    computed: {
        vehicleId: function () {
            return this.$route.query.vehicle_id || '';
        },
        builderURL: function () {
            return `/builder?vehicle_make=${this.vehicle.data?.Make}&vehicle_model=${encodeURIComponent(this.vehicle.data?.Model)}&vehicle_year=${this.vehicle.data?.Year}`;
        },
        createVehicleRequest: function () {
            let path = '/vehicles';
            return `${path}?i-img001=350,960,2400&i-img014=350,960,2400&i-img032=350,960,2400&i-colors&i-fitment&i-coordinates=1,14,32&p-size=500&f-id=${this.vehicleId}`;
        }
    },
    methods: {
        loader: async function () {
            if (this.vehicleId) {
                const vehicles = await apiRequest(this.createVehicleRequest);
                const vehicleData = this.getVehicleData(vehicles);

                this.vehicle.anglesImgCodes = vehicleData.anglesImgCodes;
                this.vehicle.coordinates = vehicleData.coordinates;
                this.vehicle.colorBaseUrl = vehicleData.colorBaseUrl;
                this.vehicle.colorDefault = vehicleData.colorDefault;
                this.vehicle.data = vehicleData.vehicle;
                this.vehicle.inImageWheelDiameters = vehicleData.inImageWheelDiameters;
                this.vehicle.imgBaseUrl = vehicleData.imgBaseUrl;
            }
            
            const storedWheel = this.$store.getters.vehicleBuilding;
            if (this.$store.getters.vehicleBuilding.wheel) {
                this.wheel.data = storedWheel.wheel;
                this.wheel.imgBaseUrl = storedWheel.wheelBaseUrl;
                this.wheel.diameterSelected = storedWheel.wheelDiameterSelected;
            }

            this.accessory.selected = this.accessory.store[0]; 
        },
        getVehicleData: function (response) {
            try {
                return {
                    vehicle: {
                        ...response.Vehicles[0],
                        ColorSelected: response.Vehicles[0].ImgColorId
                    },
                    imgBaseUrl: response.ImgUrlBase,
                    inImageWheelDiameters: response.Vehicles[0].InImageWheelDiameters || null,
                    colorBaseUrl: response.SwatchUrlBase,
                    colorDefault: response.Vehicles[0].ImgColorId || null,
                    coordinates: {
                        '1': this.getVehicleCoordinates(response, 'Coordinates001'),
                        '14': this.getVehicleCoordinates(response, 'Coordinates014'),
                        '32': this.getVehicleCoordinates(response, 'Coordinates032')
                    },
                    anglesImgCodes: {
                        '1': response.Vehicles[0].WheelAngles001 || null,
                        '14': response.Vehicles[0].WheelAngles014 || null,
                        '32': response.Vehicles[0].WheelAngles032 || null
                    }
                };
            } catch (error) {
                return {
                    vehicle: null,
                    imgBaseUrl: null,
                    colorBaseUrl: null,
                    colorDefault: null,
                    coordinates: null,
                    anglesImgCodes: null
                };
            }
        },
        getVehicleCoordinates: function (response, id) {
            if (response && response.Vehicles) {
                if (response.Vehicles[0]) {
                    return response.Vehicles[0][id] || null;                    
                }
            }
            return null;
        },
        accessorySelect: function (index) {
            this.accessory.selected = this.accessory.store[index];
            this.accessory.showOnVehicle = true;
        },
        showOriginalVehicleImage: function (val) {
            this.accessory.showOnVehicle = val;
        },
        showSpecifications: function (val) {
            this.accessory.showSpecifications = val;
        }
    },
    beforeMount: async function() {        
        if (!('baseUrl' in this.$store.getters.vehiclesMakes)) {
            let makes = await apiRequest('/vehicles/makes?f-types=Truck,Van,Car,SUV&i-logos&p-size=500');
            this.$store.commit('vehiclesMakes', {baseUrl: makes.LogoUrlBase || null, makes: makes.Makes || null});
        }

        await this.loader();

        this.filters.show = true;
        this.page.show = this.vehicle.data ? true : false;
        this.page.loading = this.vehicle.data ? false : true;
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/exportable.scss";

.options-button {
    position: absolute;
    top: 1rem;
    right: 2rem;
    z-index: 2;
}

.go-back-button {
    position: absolute;
    top: 1rem;
    left: 2rem;
    z-index: 9;
}

.tools {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.visualizer-container {
    z-index: 1;

    &:not(.allow-overflow) {
        max-height: 100vh;
    }
    
    &.allow-overflow {
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    &::-webkit-scrollbar {
        display: none;
    }

    &.remove-max-heigth {
        max-height: none;
    }
}

.previewer {
    .loading {
        filter: grayscale(100%) blur(1px);
    }

    .previewer-disabled {
        filter: opacity(1);
    }

    .previewer-disabled-message {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        height: 100%;
        width: 100%;

        & .bg-disabled-message {
            background-color: rgba($color: white, $alpha: 1);
        }
    }
}

.landscape-margin {
    margin-top: 2.5rem !important;
}

.accessory-card {
    cursor: pointer;
    
    &:hover, &:active {
        box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important;
        @extend .bg-brand-light-gray;
    }

    &.border {
        border-color: var(--client-color) !important;

        &.border-color-brand-light-gray {
            border-color: transparent !important;
        }

        &:active {
            transform: none;
        }
    }

    .accessory-image-container {
        min-height: 100px;
        min-width: 100px;
    }

    .accessory-image {
        max-height: 150px;
        max-width: 80%;
    }

    .accessory-model {
        margin-top: -10px;
        line-height: 1.1rem;
    }

    .diameter-option {
        &.diameter-selected {
            border-color: var(--client-color) !important;
        }

        &:hover {
            background-color: rgba($color: #000000, $alpha: .1);
        }
    }
}

@media only screen and (max-width: 576px) {
    .accessory-model {
        span {
            font-size: 13px;
        }

        small {
            font-size: 11px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .go-back-button {
        position: absolute;
        top: 1rem;
        left: .5rem;
    }

    .options-button {
        right: .5rem;
    }
}
</style>
