<template>
    <div class="position-relative">
        <Overlay
            v-if="open && vehicle.data.Id && wheel.data.StyleId"
            center
            remove-padding
            hide-overflow
            open
            @close="close"
        >
            <div class="zoom-container">
                <div
                    v-if="vehicle && wheel"
                    :class="[
                        'previewer-container shadow-sm',
                        $store.getters.device.isLandscape ? 'landscape':''
                    ]"
                >
                    <div
                        :class="[
                            'make-container',
                            'my-2 mx-2 mx-sm-2 mx-md-4 ps-md-2'
                        ]"
                        style="z-index: 1"
                    >
                        <img class="cursor-pointer" :src="vehicleMakeLogoURL" :alt="vehicle.data.Make" width="40" @click.stop="$router.push(`/vehicles/make/${vehicle.data.Make}`)">
                        <div class="d-inline cursor-pointer" @click.stop="$router.push(`/builder?vehicle_id=${vehicle.data.Id}`)">
                            <small class="fw-medium px-3">{{vehicle.data.Year}} {{vehicle.data.Make}}</small>
                            <small class="fw-medium">|</small>
                            <small class="fw-medium px-3">{{vehicle.data.Model}}</small>
                            <small class="fw-medium">|</small>
                            <small class="fw-medium px-3">{{vehicle.data.Submodel}}</small>
                        </div>
                    </div>

                    <div class="container-fluid h-100 m-0 p-0">
                        <div class="row justify-content-center align-items-strech h-100 mx-2 mx-md-4" style="z-index: -1">
                            <div class="col-12 h-100 d-flex justify-content-center justify-content-md-between align-items-center px-sm-5 px-xl-4 vehicle-view">
                                <div
                                    :class="[
                                        'd-flex justify-content-center position-relative',
                                        sideViewIsSelected ? 'align-items-end vehicle-view-selected':'align-items-center vehicle-view-unselected',
                                        carrouselOrder.side, 
                                    ]"
                                >
                                    <VehicleImageWithAccessories
                                        v-if="vehicleImages.img001"
                                        side="side"
                                        :coordinates="vehicle.coordinates"
                                        :angle-codes="vehicle.anglesImgCodes"
                                        :vehicle-image="vehicleImages.img001"
                                        :vehicle-wheel-diameter="vehicle.inImageWheelDiameters"
                                        :wheel-images="wheelImages"
                                        :wheel-diameter="wheel.diameterSelected"
                                        :accesoryImage="accessory.images.side"
                                        :accessory-show-on-vehicle="accessoryShowOnVehicle"
                                        :selected="sideViewIsSelected"
                                        @imageLoaded="imageIsLoaded"
                                        @viewSelected="viewSelected('side', true)"
                                        @touchstart="touchStart"
                                    />
                                </div>

                                <div
                                    :class="[
                                        'd-flex justify-content-center position-relative',
                                        frontViewIsSelected ? 'align-items-end vehicle-view-selected':'align-items-center vehicle-view-unselected',
                                        carrouselOrder.front 
                                    ]"
                                >
                                    <VehicleImageWithAccessories
                                        v-if="vehicleImages.img032"
                                        side="front"
                                        :coordinates="vehicle.coordinates"
                                        :angle-codes="vehicle.anglesImgCodes"
                                        :vehicle-image="vehicleImages.img032"
                                        :vehicle-wheel-diameter="vehicle.inImageWheelDiameters"
                                        :wheel-images="wheelImages"
                                        :wheel-diameter="wheel.diameterSelected"
                                        :accesoryImage="accessory.images.front"
                                        :accessory-show-on-vehicle="accessoryShowOnVehicle"
                                        :selected="frontViewIsSelected"
                                        @imageLoaded="imageIsLoaded"
                                        @viewSelected="viewSelected('front', true)"
                                        @touchstart="touchStart"
                                    />
                                </div>

                                <div
                                    :class="[
                                        'd-flex justify-content-center position-relative',
                                        backViewIsSelected ? 'align-items-end vehicle-view-selected':'align-items-center vehicle-view-unselected',
                                        carrouselOrder.back 
                                    ]"
                                >
                                    <VehicleImageWithAccessories
                                        v-if="vehicleImages.img014"
                                        side="back"
                                        :coordinates="vehicle.coordinates"
                                        :angle-codes="vehicle.anglesImgCodes"
                                        :vehicle-image="vehicleImages.img014"
                                        :vehicle-wheel-diameter="vehicle.inImageWheelDiameters"
                                        :wheel-images="wheelImages"
                                        :wheel-diameter="wheel.diameterSelected"
                                        :accesoryImage="accessory.images.back"
                                        :accessory-show-on-vehicle="accessoryShowOnVehicle"
                                        :selected="backViewIsSelected"
                                        @imageLoaded="imageIsLoaded"
                                        @viewSelected="viewSelected('back', true)"
                                        @touchstart="touchStart"
                                    />
                                </div>

                                <div
                                    class="d-flex carrousel-arrow-controller ps-md-2"
                                    style="left: 0"
                                >
                                    <span 
                                        class="carrousel-arrow-option toggle-active cursor-pointer user-select-none p-1 rounded"
                                        @click.stop="moveView('previous')"
                                    >
                                        <Icon icon-name="navArrowLeft"/>
                                    </span>
                                </div>
                                
                                <div
                                    class="d-flex carrousel-arrow-controller pe-md-2"
                                    style="right: 0"
                                >
                                    <span
                                        class="carrousel-arrow-option toggle-active cursor-pointer user-select-none p-1 rounded"
                                        @click.stop="moveView('next')"
                                    >
                                        <Icon icon-name="navArrowRight"/>
                                    </span>
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <slot/>
                </div>
            </div>
        </Overlay>
    </div>
</template>

<script>
import Overlay from '@/components/shared/Overlay.vue';
import VehicleImageWithAccessories from '@/components/builder/VehicleImageWithAccessories.vue';
import Icon from '@/components/shared/Icon.vue';

// import VehicleImageWithAccessories from '@/components/builder/VehicleImageWithAccessories.vue';
// import ImagesCarrouselController from '@/components/shared/ImagesCarrouselController.vue';
// import ShareButton from '@/components/shared/ShareButton.vue';

export default {
    name: "ZoomVehicleAccessories",
    components: {
        Icon,
        Overlay,
        VehicleImageWithAccessories,
        // ImagesCarrouselController,
        // ShareButton
    },
    emits: ["close"],
    props: {
        vehicle: {
            type: Object,
            required: true
        },
        wheel: {
            type: Object,
            required: true
        },
        accessory: {
            type: Object,
            required: true
        },
        accessoryShowOnVehicle: {
            type: Boolean,
            required: true
        },
        open: {
            type: Boolean,
            required: true
        }
    },
    data: function () {
        return {
            carrousel: {
                currentView: 'front',
                backViewState: false,
                frontViewState: false,
                sideViewState: false
            },
            component: {
                show: false
            }
        }
    },
    computed: {
        carrouselOrder: function () {
            if (this.carrousel.currentView === 'back') {
                return {
                    side: 'order-2',
                    front: 'order-0',
                    back: 'order-1'
                };
            }

            if (this.sideViewIsSelected) {
                return {
                    side: 'order-1',
                    front: 'order-2',
                    back: 'order-0'
                };
            }
            
            return {
                side: 'order-0',
                front: 'order-1',
                back: 'order-2'
            };
        },
        carrouselPreviousOptionIsDisabled: function () {
            let val = true;
            
            if (this.frontViewIsSelected && this.carrousel.sideViewState) val = false;
            if (this.backViewIsSelected && this.carrousel.frontViewState) val = false;
            if (this.sideViewIsSelected && this.carrousel.backViewState) val = false;
            
            return val;
        },
        carrouselNextOptionIsDisabled: function () {
            let val = true;

            if (this.frontViewIsSelected && this.carrousel.backViewState) val = false;
            if (this.backViewIsSelected && this.carrousel.sideViewState) val = false;
            if (this.sideViewIsSelected && this.carrousel.frontViewState) val = false;

            return val;
        },
        backViewIsSelected: function () {
            return this.carrousel.currentView === 'back';
        },
        frontViewIsSelected: function () {
            return this.carrousel.currentView === 'front';
        },
        sideViewIsSelected: function () {
            return this.carrousel.currentView === 'side';
        },
        vehicleMakeLogoURL: function () {
            const makes = this.$store.getters.vehiclesMakes.makes;
            const makeBaseUrl = this.$store.getters.vehiclesMakes.baseUrl;
            const makeId = this.vehicle.data.Make;
            const filterMake = (make) => make.Make === makeId;
            const make = makes.filter(filterMake);

            if (make && make.length > 0) {
                return `${makeBaseUrl}${make[0].Logo}`;
            }
            return null;
        },
        vehicleImages: function () {
            return {
                img001: this.vehicle.data.Img001 ? `${this.vehicle.imgBaseUrl}${this.vehicle.data.Img001['2400']}` : null,
                img014: this.vehicle.data.Img014 ?  `${this.vehicle.imgBaseUrl}${this.vehicle.data.Img014['2400']}` : null,
                img032: this.vehicle.data.Img032 ?  `${this.vehicle.imgBaseUrl}${this.vehicle.data.Img032['2400']}` : null
            }
        },
        wheelImages: function () {
            return {
                imgBaseUrl: this.wheel.imgBaseUrl,
                images: {
                    Img0001: this.wheel.data.Img0001 || null,
                    Img0002: this.wheel.data.Img0002 || null,
                    Img0003: this.wheel.data.Img0003 || null,
                    Img0100: this.wheel.data.Img0100 || null,
                    Img0200: this.wheel.data.Img0200 || null,
                    Img0300: this.wheel.data.Img0300 || null
                }
            }
        }
    },
    methods: {
        close: function () {
            this.$emit('close');
        },
        imageIsLoaded: function (response) {
            const {view, value} = response;
            this.carrousel[`${view}ViewState`] = value;
        },
        showOriginalVehicleImage: function () {
            this.$emit('showOriginalVehicleImage', !this.accessoryShowOnVehicle);
        },
        showSpecifications: function () {
            this.specifications.show = !this.specifications.show;
            this.$emit('showSpecifications', this.specifications.show);
        },
        viewSelected: function(view) {
            this.carrousel.currentView = view;
        },
        moveView: function (option) {
            if (this.carrouselPreviousOptionIsDisabled && option === 'previous') {
                option = 'next';
            }

            if (this.carrouselNextOptionIsDisabled && option === 'next') {
                option = 'previous';
            }
            
            switch (option) {
                case 'previous':
                    if (this.frontViewIsSelected) {
                        this.viewSelected('side');
                    } else if (this.sideViewIsSelected) {
                        this.viewSelected('back');
                    } else if (this.backViewIsSelected) {
                        this.viewSelected('front');
                    }
                    break;

                case 'next':
                    if (this.frontViewIsSelected) {
                        this.viewSelected('back');
                    } else if (this.sideViewIsSelected) {
                        this.viewSelected('front');
                    } else if (this.backViewIsSelected) {
                        this.viewSelected('side');
                    }
                    break;
            
                default:
                    break;
            }
        },
        touchStart: function (touchEvent) {
            if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
                return;
            }
            const posXStart = touchEvent.changedTouches[0].clientX;
            addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart), {once: true});
        },
        touchEnd: function (touchEvent, posXStart) {
            if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
                return;
            }
            const posXEnd = touchEvent.changedTouches[0].clientX;
            if (posXStart < posXEnd && !this.carrouselPreviousOptionIsDisabled) {
                this.moveView('previous'); // swipe right
            } else if (posXStart > posXEnd && !this.carrouselNextOptionIsDisabled) {
                this.moveView('next'); // swipe left
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/exportable.scss";

.zoom-container {
    position: relative;
    width: 96vw;
    height: 86vh;
    max-height: calc(96vw * 1250/2400);
    max-width: 96vw;
    cursor: auto;
    .client-logo-container {
        position: absolute;
        padding: 0 0;
        top: 0;
        left: 0;
        height: 80px;

        &.small,
        &.mobile {
            background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(157,157,157,.8) 70%, rgba(194,194,194,.6) 86%, rgba(224,224,224,.1) 94%, rgba(241,241,241,0) 100%);
        }
        
        &.small {
            height: 40px;
        }

        &.mobile {
            height: 60px;
        }
    }

    .info-container {
        position: absolute;
        padding: 0 0;
        bottom: 0%;
        left: 0;
        height: 90px;
        background-color: rgba(white, .6803);

        .vehicle-make-logo {
            max-height: 65px;
            max-width: 65px;
        }

        &.small {
            height: 40px;
            font-size: 60%;
            line-height: 1;

            .vehicle-make-logo {
                max-height: 40px;
                max-width: 40px;
            }
        }

        &.mobile {
            height: 110px;
            font-size: 82%;
            line-height: 1.15;

            .vehicle-make-logo {
                max-height: 45px;
                max-width: 45px;
            }
        }
    }

    &.zoom-mobile {
        height: 76vh;
        width: calc(76vh * 1250/2400);
        max-height: 76vh;
    }

    .zoom-content {
        max-height: 86vh;
    }

    &.zoom-mobile {
        &,
        .zoom-content {
            max-height: 86vh;
        }
    }
}
.previewer-container {
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-image: url('https://vvs.autosyncstudio.com/static/backgrounds/builder-desktop.png');
    position: relative;
    height: 86vh;
    max-height: calc(96vw * 1250/2400);
    width: 100%;
    z-index: 0;

    .make-container {
        position: absolute;
        bottom: 0;
        left: 0;

        img {
            max-width: 80px;
            max-height: 3rem;
        }
    }

    .accessory-short-specs {
        span {
            font-size: 92%;
        }
    }

    .vehicle-view {
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url('https://vvs.autosyncstudio.com/static/backgrounds/builder-line-desktop.svg');
        position: relative;

        animation: fade-in-image;
        animation-delay: .3s;
        animation-duration: .3s;
        animation-fill-mode: forwards;
        -webkit-animation: fade-in-image;
        -webkit-animation-delay: .3s;
        -webkit-animation-duration: .3s;
        -webkit-animation-fill-mode: forwards;
        display: none;
        opacity: 0;

        &#{-selected}, &#{-unselected} {
            transition: .3s;

            img {
                cursor: pointer;
            }
        }

        &#{-selected} {
            opacity: 1;
            width: 100%;

            img {
                width: 70%;
            }
        }

        &#{-unselected} {
            opacity: .61803;
            width: 0%;

            img {
                width: 70%;
            }
        }
    }

    .carrousel {
        &#{-arrow-controller} {
            display: block;
            position: absolute;
            z-index: 4;
        }

        &#{-arrow-option} {
            background-color: rgba($color: white, $alpha: .3);
            color: rgba($color: black, $alpha: .5);
            font-size: 1.803rem;

            &:hover {
                color: rgba($color: black, $alpha: .61803);
            }

            &.carrousel-arrow-option-disabled {
                background-color: rgba($color: white, $alpha: .05);
                color: rgba($color: black, $alpha: .1);
                cursor: auto;
            }
        }
    }
}

@media only screen and (min-width: 577px) and (max-width: 1199px) {
    .previewer-container {
        .vehicle-view {
            &#{-selected} {
                width: 100%;

                img {
                    width: 80%;
                }
            }

            &#{-unselected} {
                width: 0%;

                img {
                    width: 80%;
                }
            }
        }
    
        &.landscape {
            height: 100vh;

            .vehicle-view {
                width: 100%;
                margin-left: auto;
                margin-right: auto;

                &#{-selected} {
                    width: calc(76.394% * 0.86);
                }

                &#{-unselected} {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    .previewer-container {
        background-image: url('https://vvs.autosyncstudio.com/static/backgrounds/builder-mobile.png');
    
        .vehicle-view {
            background-image: url('https://vvs.autosyncstudio.com/static/backgrounds/builder-line-mobile.svg');

            &#{-selected} {
                width: 86%;
                margin-top: .5em;
                
                img {
                    width: 75%;
                }
            }

            &#{-unselected} {
                display: none !important;
            }
        }
    
        &.landscape {
            height: 100vh;

            .vehicle-view {
                width: 100%;
                margin-left: auto;
                margin-right: auto;

                &#{-selected} {
                    width: calc(76.394% * 0.86);
                }

                &#{-unselected} {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    
        .make-container {
            img {
                max-width: 60px;
                max-height: 2.5rem;
            }
        }
    }
}
</style>
