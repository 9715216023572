<template>
    <div class="user-select-none">
        <!-- Desktop bar -->
        <div class="bg-brand-body position-relative">
            <div class="shadow-sm d-none d-lg-block desktop">
                <div class="d-flex justify-content-start align-items-center m-0 mx-3 mx-sm-4 py-3 px-sm-4">
                    <div class="d-flex justify-content-start align-items-center m-0 p-0 pe-3">
                        <div class="pe-3 pe-xl-5 me-3 me-xl-5 border-end border-2">
                            <div class="filters-tags fs-span">
                                <div class="d-flex justify-content-end align-items-center ps-1 fw-medium">
                                    <span>BRAND: </span>
                                    <span class="filter-option-selected-desktop ms-2 fw-regular">ALL</span>
                                </div>
                            </div>
                        </div>
                        <div class="pe-3 pe-xl-5 me-3 me-xl-5 border-end border-2">
                            <div class="filters-tags fs-span">
                                <div class="d-flex justify-content-end align-items-center ps-1 fw-medium">
                                    <span>TYPE: </span>
                                    <span class="filter-option-selected-desktop ms-2 fw-regular">ALL</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Mobile bar -->
            <div class="shadow-sm d-block d-lg-none">
                <div class="d-flex justify-content-start align-items-center fw-regular filters-tags">
                    <div class="d-flex justify-content-center aling-items-center px-2 py-2 px-sm-4">
                        <small>ALL ACCESSORIES</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FiltersAccessories',
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/exportable.scss";

.icon-size {
    font-size: 20px;
}

.filters-tags {
    &:hover.desktop {
        color: #000000 !important;
    }
}

.filter-option {
    &:hover {
        @extend .bg-brand-light-gray;
    }

    &.selected {
        border-color: var(--client-color) !important;
        @extend .bg-brand-light-gray;
    }
}

.fs-count {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
}

.fs-span {
    font-size: .9rem;
}

.options-container {
    .options-filter {
        border-bottom: 1px solid rgba($color: white, $alpha: .2);;
    }
}

.filter-option-selected-desktop,
.filter-option-selected-mobile,
.detail-option-selected {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.filter-option-selected-desktop,
.filter-option-selected-mobile {
    text-align: right;
}

.filter-option-selected-desktop {
    max-width: 155px;
}

.filter-option-selected-mobile {
    width: 155px;
}

.detail-option-selected {
    width: 200px;
}

.grid-filters{
    border-right: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;

    p {
        font-size: 10px;
        line-height: 1;
    }

    span.brand-name {
        font-size: 10px;
    }
}

@media only screen and (max-width: 576px) {
    .grid-filters {
        border-bottom: none;
        border-right: none;

        &:nth-child(1) {
            border-top: none;
        }
    }
}

@media only screen and (min-width: 577px) and (max-width: 767px) {
    .icon-size {
        font-size: 18px;
    }

    .filters-tags {
        font-size: .88rem;
        
        &:active {
            transform: scale(.995);
        }
    }

    .grid-filters {
        &:nth-child(1), &:nth-child(2) {
            border-top: none;
        }

        &:nth-child(1).more-than-2, &:nth-child(2).more-than-2 {
            border-top: none;
            border-bottom: 1px solid #dee2e6;
        }

        &:nth-child(3).more-than-2, &:nth-child(4).more-than-2 {
            border-top: none;
        }
        
        &:nth-child(2n) {
            border-right: none;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1399px) {
    .filter-option-selected-desktop {
        width: 40px;
    }

    .fs-span {
        font-size: .85rem;
    }

    .grid-filters {
        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            border-top: none;
        }

        &:nth-child(1).more-than-3, &:nth-child(2).more-than-3, &:nth-child(3).more-than-3 {
            border-top: none;
            border-bottom: 1px solid #dee2e6;
        }

        &:nth-child(4).more-than-3, &:nth-child(5).more-than-3, &:nth-child(6).more-than-3 {
            border-top: none;
        }
        
        &:nth-child(3n) {
            border-right: none;
        }
    }
}

@media only screen and (min-width: 1400px) {
    .grid-filters {
        &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
            border-top: none;
        }
        
        &:nth-child(1).more-than-6, &:nth-child(2).more-than-6, &:nth-child(3).more-than-6,
        &:nth-child(4).more-than-6, &:nth-child(5).more-than-6, &:nth-child(6).more-than-6 {
            border-top: none;
            border-bottom: 1px solid #dee2e6;
        }

        &:nth-child(7).more-than-6, &:nth-child(8).more-than-6, &:nth-child(9).more-than-6,
        &:nth-child(10).more-than-6, &:nth-child(11).more-than-6, &:nth-child(12).more-than-6 {
            border-top: none;
        }
        
        &:nth-child(6n) {
            border-right: none;
        }
    }
}
</style>
