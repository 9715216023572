<template>
    <div class="position-relative w-100 h-100">
        <img
            alt=""
            :src="vehicleImage"
            style="opacity: 0"
            :class="selected ? 'w-100':'w-50'"
        >
        <VehicleImage
            :class="[
                'vehicle-image',
                'main-image',
                !component.loading && 'loaded'
            ]"
            :vehicle-large-image="vehicleImage"
            :selected="selected"
            :view-side="side"
            :wheel-images="wheelImages"
            :coordinates="coordinates"
            :angles-img-codes="angleCodes"
            :vehicle-image-wheel-diameter="vehicleWheelDiameter"
            :wheel-diameter="wheelDiameter"
            clicklable
            @viewSelected="viewSelected"
            @imageLoaded="imageLoaded"
            @showWheels="showWheels"
            @touchstart="touchStart"
        />

        <div
            v-if="accessoryShowOnVehicle && !component.error"
            class="w-100 h-100 accessory-container d-flex justify-content-center align-items-center"
            @click.stop="viewSelected"
            @touchstart="touchStart"
        >
            <img
                v-show="buildImage.loaded"
                alt=""
                :class="[
                    'h-100',
                    selected ? 'w-100':'w-50'
                ]"
                :src="accesoryImage"
            >
        </div>
    </div>
</template>

<script>
import VehicleImage from '@/components/builder/VehicleImage.vue';

export default {
    name: 'VehicleImageWithAccessories',
    components: {
        VehicleImage
    },
    emits: [
        'viewSelected',
        'showWheels',
        'touchstart',
        'imageLoaded'
    ],
    props: {
        coordinates: {
            type: Object,
            required: true
        },
        angleCodes: {
            type: Object,
            required: true
        },
        vehicleImage: {
            type: String,
            required: true
        },
        vehicleWheelDiameter: {
            type: Object,
            required: true
        },
        wheelImages: {
            type: Object,
            required: true
        },
        wheelDiameter: {
            type: Number,
            required: true
        },
        accesoryImage: {
            type: String,
            required: true
        },
        accessoryShowOnVehicle: {
            type: Boolean,
            required: true
        },
        selected: {
            type: Boolean,
            required: true
        },
        side: {
            type: String,
            required: true
        }
    },
    data: function () {
        return {
            component: {
                error: false,
                loading: false,
            },
            buildImage: {
                loaded: false
            }
        };
    },
    methods: {
        viewSelected: function () {
            this.$emit('viewSelected');
        },
        showWheels: function (value) {
            this.component.loading = false;

            if (this.oldVehicleUpdated) {
                this.oldVehicleUpdated = false;
                this.oldVehicle.coordinates = this.coordinates;
                this.oldVehicle.angleCodes = this.angleCodes;
            }

            if (this.oldWheelImages != this.wheelImages) {
                setTimeout(async () => {
                    this.oldWheelImages = {...this.wheelImages};
                }, 680);
            }
            
            this.component.error = !value.allow;
            this.$emit('showWheels', value);
        },
        showOldWheels: function (value) {
            this.showOldImage = value.allow;
        },
        touchStart: function (touchEvent) {
            this.$emit('touchstart', touchEvent);
        },
        imageLoaded: function () {
            const view = this.side;
            const value = !this.component.error;
            this.buildImage.loaded = value;
            this.$emit('imageLoaded', {view, value});
        }
    },
    watch: {
        wheelImages: function () {
            this.component.loading = true;
        }
    }
}
</script>

<style scoped lang="scss">
.vehicle-image {
    position: absolute;
    top: 0;
    left: 0;

    &.main-image {
        opacity: 0;
        filter: grayscale(100%);
        z-index: 1;

        &.loaded {
            opacity: 1;
            filter: grayscale(0);
            transition: opacity .6s ease, filter 1.2s ease-in-out;
            -o-transition: opacity .6s ease, filter 1.2s ease-in-out;
            -moz-transition: opacity .6s ease, filter 1.2s ease-in-out;
            -webkit-transition: opacity .6s ease, filter 1.2s ease-in-out;
        }
    }

    .preloader-image {
        opacity: 1;
        filter: grayscale(100%);
        z-index: 0;

        &.loaded {
            opacity: 0;
            transition: opacity 1.3606s ease;
            -o-transition: opacity 1.3606s ease;
            -moz-transition: opacity 1.3606s ease;
            -webkit-transition: opacity 1.3606s ease;
        }
    }
}

.accessory-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}
</style>