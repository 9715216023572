<template>
    <div
        v-if="vehicle && wheel"
        :class="[
            'previewer-container shadow-sm',
            $store.getters.device.isLandscape ? 'landscape':''
        ]"
    >
        <div
            :class="[
                'make-container',
                'my-2 mx-2 mx-sm-2 mx-md-4 ps-md-2'
            ]"
            style="z-index: 1"
        >
            <img class="cursor-pointer" :src="vehicleMakeLogoURL" :alt="vehicle.data.Make" width="40" @click.stop="$router.push(`/vehicles/make/${vehicle.data.Make}`)">
            <div class="d-inline cursor-pointer" @click.stop="$router.push(`/builder?vehicle_id=${vehicle.data.Id}`)">
                <small class="fw-medium px-3">{{vehicle.data.Year}} {{vehicle.data.Make}}</small>
                <small class="fw-medium">|</small>
                <small class="fw-medium px-3">{{vehicle.data.Model}}</small>
                <small class="fw-medium">|</small>
                <small class="fw-medium px-3">{{vehicle.data.Submodel}}</small>
            </div>
        </div>

        <div class="container-fluid h-100 m-0 p-0">
            <div class="row justify-content-center align-items-strech h-100 mx-2 mx-md-4" style="z-index: -1">
                <div class="col-12 col-lg-8 h-100 d-flex justify-content-center justify-content-md-between align-items-center px-sm-5 px-xl-4 vehicle-view">
                    <div
                        :class="[
                            'd-flex justify-content-center position-relative',
                            sideViewIsSelected ? 'align-items-end vehicle-view-selected':'align-items-center vehicle-view-unselected',
                            carrouselOrder.side, 
                        ]"
                    >
                        <VehicleImageWithAccessories
                            v-if="vehicleImages.img001"
                            side="side"
                            :coordinates="vehicle.coordinates"
                            :angle-codes="vehicle.anglesImgCodes"
                            :vehicle-image="vehicleImages.img001"
                            :vehicle-wheel-diameter="vehicle.inImageWheelDiameters"
                            :wheel-images="wheelImages"
                            :wheel-diameter="wheel.diameterSelected"
                            :accesoryImage="accessory.images.side"
                            :accessory-show-on-vehicle="accessoryShowOnVehicle"
                            :selected="sideViewIsSelected"
                            @imageLoaded="imageIsLoaded"
                            @viewSelected="viewSelected('side', true)"
                            @touchstart="touchStart"
                        />
                    </div>

                    <div
                        :class="[
                            'd-flex justify-content-center position-relative',
                            frontViewIsSelected ? 'align-items-end vehicle-view-selected':'align-items-center vehicle-view-unselected',
                            carrouselOrder.front 
                        ]"
                    >
                        <VehicleImageWithAccessories
                            v-if="vehicleImages.img032"
                            side="front"
                            :coordinates="vehicle.coordinates"
                            :angle-codes="vehicle.anglesImgCodes"
                            :vehicle-image="vehicleImages.img032"
                            :vehicle-wheel-diameter="vehicle.inImageWheelDiameters"
                            :wheel-images="wheelImages"
                            :wheel-diameter="wheel.diameterSelected"
                            :accesoryImage="accessory.images.front"
                            :accessory-show-on-vehicle="accessoryShowOnVehicle"
                            :selected="frontViewIsSelected"
                            @imageLoaded="imageIsLoaded"
                            @viewSelected="viewSelected('front', true)"
                            @touchstart="touchStart"
                        />
                    </div>

                    <div
                        :class="[
                            'd-flex justify-content-center position-relative',
                            backViewIsSelected ? 'align-items-end vehicle-view-selected':'align-items-center vehicle-view-unselected',
                            carrouselOrder.back 
                        ]"
                    >
                        <VehicleImageWithAccessories
                            v-if="vehicleImages.img014"
                            side="back"
                            :coordinates="vehicle.coordinates"
                            :angle-codes="vehicle.anglesImgCodes"
                            :vehicle-image="vehicleImages.img014"
                            :vehicle-wheel-diameter="vehicle.inImageWheelDiameters"
                            :wheel-images="wheelImages"
                            :wheel-diameter="wheel.diameterSelected"
                            :accesoryImage="accessory.images.back"
                            :accessory-show-on-vehicle="accessoryShowOnVehicle"
                            :selected="backViewIsSelected"
                            @imageLoaded="imageIsLoaded"
                            @viewSelected="viewSelected('back', true)"
                            @touchstart="touchStart"
                        />
                    </div>

                    <div
                        class="d-flex carrousel-arrow-controller ps-md-2"
                        style="left: 0"
                    >
                        <span 
                            class="carrousel-arrow-option toggle-active cursor-pointer user-select-none p-1 rounded"
                            @click.stop="moveView('previous')"
                        >
                            <Icon icon-name="navArrowLeft"/>
                        </span>
                    </div>
                    
                    <div
                        class="d-flex carrousel-arrow-controller pe-md-2"
                        style="right: 0"
                    >
                        <span
                            class="carrousel-arrow-option toggle-active cursor-pointer user-select-none p-1 rounded"
                            @click.stop="moveView('next')"
                        >
                            <Icon icon-name="navArrowRight"/>
                        </span>
                    </div>
                </div>
                
                <div class="d-none d-xl-block col-12 col-lg-4 position-relative">
                    <div class="d-flex flex-column justify-content-center align-items-start h-100 ps-5 accessory-short-specs">
                        <div class="mb-3 pb-3 border-bottom w-50">
                            <img :src="accessory.logoURL" alt="Accessories brand" height="40">
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-start">
                            <div>
                                <h1 class="fw-bold m-0 p-0" style="font-size: 2.5em"> <span class="text-uppercase">{{accessory.model}}</span> </h1>
                            </div>
                            <div style="font-size: 86%">
                                <small class="fw-bold"> <span class="text-uppercase">{{accessory.type}}</span> </small>
                            </div>
                            <div class="fw-regular text-brand-muted" style="font-size: 86%">
                                <small> <span class="text-uppercase">{{accessory.partNumber}}</span> </small>
                            </div>
                        </div>

                        <div class="mt-3">
                            <div
                                class="d-none d-xxl-block mb-3 text-center text-brand-primary border border-color-brand-primary rounded cursor-pointer toggle-active user-select-none text-nowrap px-4 py-1"
                                style="letter-spacing: 1px"
                                @click.stop="showOriginalVehicleImage"
                            >
                                <small v-if="accessoryShowOnVehicle" class="title-section-spacing fw-medium">SHOW ORIGINAL VEHICLE</small>
                                <small v-else class="title-section-spacing fw-medium">SHOW ON VEHICLE</small>
                            </div>
                        </div>

                        <div>
                            <div
                                class="rounded px-3 py-2 bg-brand-primary text-white cursor-pointer toggle-active user-select-none"
                                @click.stop="showSpecifications"
                            >
                                <div class="d-flex justify-content-center align-items-center">
                                    <small class="d-none d-sm-inline me-3 fw-medium" style="letter-spacing: 1px">INFO / BUY</small>
                                    <Icon v-if="specifications.show" icon-name="navArrowUp"/>
                                    <Icon v-else icon-name="navArrowDown"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <slot/>
    </div>
</template>

<script>
import VehicleImageWithAccessories from '@/components/builder/VehicleImageWithAccessories.vue';
import Icon from '@/components/shared/Icon.vue';

export default {
    name: 'AccessoriesPreviewer',
    components: {
        VehicleImageWithAccessories,
        Icon
    },
    emits: [
        'showOriginalVehicleImage',
        'showSpecifications'
    ],
    props: {
        vehicle: {
            type: Object,
            required: true
        },
        wheel: {
            type: Object,
            required: true
        },
        accessory: {
            type: Object,
            required: true
        },
        accessoryShowOnVehicle: {
            type: Boolean,
            required: true
        }
    },
    data: function () {
        return {
            carrousel: {
                currentView: 'front',
                backViewState: false,
                frontViewState: false,
                sideViewState: false
            },
            specifications: {
                show: false
            }
        }
    },
    computed: {
        carrouselOrder: function () {
            if (this.carrousel.currentView === 'back') {
                return {
                    side: 'order-2',
                    front: 'order-0',
                    back: 'order-1'
                };
            }

            if (this.sideViewIsSelected) {
                return {
                    side: 'order-1',
                    front: 'order-2',
                    back: 'order-0'
                };
            }
            
            return {
                side: 'order-0',
                front: 'order-1',
                back: 'order-2'
            };
        },
        carrouselPreviousOptionIsDisabled: function () {
            let val = true;
            
            if (this.frontViewIsSelected && this.carrousel.sideViewState) val = false;
            if (this.backViewIsSelected && this.carrousel.frontViewState) val = false;
            if (this.sideViewIsSelected && this.carrousel.backViewState) val = false;
            
            return val;
        },
        carrouselNextOptionIsDisabled: function () {
            let val = true;

            if (this.frontViewIsSelected && this.carrousel.backViewState) val = false;
            if (this.backViewIsSelected && this.carrousel.sideViewState) val = false;
            if (this.sideViewIsSelected && this.carrousel.frontViewState) val = false;

            return val;
        },
        backViewIsSelected: function () {
            return this.carrousel.currentView === 'back';
        },
        frontViewIsSelected: function () {
            return this.carrousel.currentView === 'front';
        },
        sideViewIsSelected: function () {
            return this.carrousel.currentView === 'side';
        },
        vehicleMakeLogoURL: function () {
            const makes = this.$store.getters.vehiclesMakes.makes;
            const makeBaseUrl = this.$store.getters.vehiclesMakes.baseUrl;
            const makeId = this.vehicle.data.Make;
            const filterMake = (make) => make.Make === makeId;
            const make = makes.filter(filterMake);

            if (make && make.length > 0) {
                return `${makeBaseUrl}${make[0].Logo}`;
            }
            return null;
        },
        vehicleImages: function () {
            return {
                img001: this.vehicle.data.Img001 ? `${this.vehicle.imgBaseUrl}${this.vehicle.data.Img001['960']}` : null,
                img014: this.vehicle.data.Img014 ?  `${this.vehicle.imgBaseUrl}${this.vehicle.data.Img014['960']}` : null,
                img032: this.vehicle.data.Img032 ?  `${this.vehicle.imgBaseUrl}${this.vehicle.data.Img032['960']}` : null
            }
        },
        wheelImages: function () {
            return {
                imgBaseUrl: this.wheel.imgBaseUrl,
                images: {
                    Img0001: this.wheel.data.Img0001 || null,
                    Img0002: this.wheel.data.Img0002 || null,
                    Img0003: this.wheel.data.Img0003 || null,
                    Img0100: this.wheel.data.Img0100 || null,
                    Img0200: this.wheel.data.Img0200 || null,
                    Img0300: this.wheel.data.Img0300 || null
                }
            }
        }
    },
    methods: {
        imageIsLoaded: function (response) {
            const {view, value} = response;
            this.carrousel[`${view}ViewState`] = value;
        },
        showOriginalVehicleImage: function () {
            this.$emit('showOriginalVehicleImage', !this.accessoryShowOnVehicle);
        },
        showSpecifications: function () {
            this.specifications.show = !this.specifications.show;
            this.$emit('showSpecifications', this.specifications.show);
        },
        viewSelected: function(view) {
            this.carrousel.currentView = view;
        },
        moveView: function (option) {
            if (this.carrouselPreviousOptionIsDisabled && option === 'previous') {
                option = 'next';
            }

            if (this.carrouselNextOptionIsDisabled && option === 'next') {
                option = 'previous';
            }
            
            switch (option) {
                case 'previous':
                    if (this.frontViewIsSelected) {
                        this.viewSelected('side');
                    } else if (this.sideViewIsSelected) {
                        this.viewSelected('back');
                    } else if (this.backViewIsSelected) {
                        this.viewSelected('front');
                    }
                    break;

                case 'next':
                    if (this.frontViewIsSelected) {
                        this.viewSelected('back');
                    } else if (this.sideViewIsSelected) {
                        this.viewSelected('front');
                    } else if (this.backViewIsSelected) {
                        this.viewSelected('side');
                    }
                    break;
            
                default:
                    break;
            }
        },
        touchStart: function (touchEvent) {
            if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
                return;
            }
            const posXStart = touchEvent.changedTouches[0].clientX;
            addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart), {once: true});
        },
        touchEnd: function (touchEvent, posXStart) {
            if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
                return;
            }
            const posXEnd = touchEvent.changedTouches[0].clientX;
            if (posXStart < posXEnd && !this.carrouselPreviousOptionIsDisabled) {
                this.moveView('previous'); // swipe right
            } else if (posXStart > posXEnd && !this.carrouselNextOptionIsDisabled) {
                this.moveView('next'); // swipe left
            }
        },
    }
}
</script>

<style lang="scss">
@import "../../assets/scss/exportable.scss";

.previewer-container {
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-image: url('https://vvs.autosyncstudio.com/static/backgrounds/builder-desktop.png');
    position: relative;
    height: calc(100vw * (493/1920));
    max-height: 493px;
    width: 100%;
    z-index: 0;

    .make-container {
        position: absolute;
        bottom: 0;
        left: 0;

        img {
            max-width: 80px;
            max-height: 3rem;
        }
    }

    .accessory-short-specs {
        span {
            font-size: 92%;
        }
    }

    .vehicle-view {
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        background-image: url('https://vvs.autosyncstudio.com/static/backgrounds/builder-line-desktop.svg');
        position: relative;

        animation: fade-in-image;
        animation-delay: .3s;
        animation-duration: .3s;
        animation-fill-mode: forwards;
        -webkit-animation: fade-in-image;
        -webkit-animation-delay: .3s;
        -webkit-animation-duration: .3s;
        -webkit-animation-fill-mode: forwards;
        display: none;
        opacity: 0;

        &#{-selected}, &#{-unselected} {
            transition: .3s;

            img {
                cursor: pointer;
            }
        }

        &#{-selected} {
            opacity: 1;
            width: 50%;

            img {
                width: 70%;
            }
        }

        &#{-unselected} {
            opacity: .61803;
            width: 25%;

            img {
                width: 70%;
            }
        }
    }

    .carrousel {
        &#{-arrow-controller} {
            display: block;
            position: absolute;
            z-index: 4;
        }

        &#{-arrow-option} {
            background-color: rgba($color: white, $alpha: .3);
            color: rgba($color: black, $alpha: .5);
            font-size: 1.803rem;

            &:hover {
                color: rgba($color: black, $alpha: .61803);
            }

            &.carrousel-arrow-option-disabled {
                background-color: rgba($color: white, $alpha: .05);
                color: rgba($color: black, $alpha: .1);
                cursor: auto;
            }
        }
    }
}

@media only screen and (min-width: 577px) and (max-width: 1199px) {
    .previewer-container {
        height: calc(100vw * (320/376));

        .vehicle-view {
            &#{-selected} {
                width: 60%;

                img {
                    width: 80%;
                }
            }

            &#{-unselected} {
                width: 20%;

                img {
                    width: 80%;
                }
            }
        }
    
        &.landscape {
            height: 100vh;

            .vehicle-view {
                width: 100%;
                margin-left: auto;
                margin-right: auto;

                &#{-selected} {
                    width: calc(76.394% * 0.86);
                }

                &#{-unselected} {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 576px) {
    .previewer-container {
        height: calc(100vw * (320/376));
        background-image: url('https://vvs.autosyncstudio.com/static/backgrounds/builder-mobile.png');
    
        .vehicle-view {
            background-image: url('https://vvs.autosyncstudio.com/static/backgrounds/builder-line-mobile.svg');

            &#{-selected} {
                width: 86%;
                margin-top: .5em;
                
                img {
                    width: 75%;
                }
            }

            &#{-unselected} {
                display: none !important;
            }
        }
    
        &.landscape {
            height: 100vh;

            .vehicle-view {
                width: 100%;
                margin-left: auto;
                margin-right: auto;

                &#{-selected} {
                    width: calc(76.394% * 0.86);
                }

                &#{-unselected} {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    
        .make-container {
            img {
                max-width: 60px;
                max-height: 2.5rem;
            }
        }
    }
}
</style>
